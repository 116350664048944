<template>
  <div class="head-wrap">
    <div class="search-wrap" @click="searchHandle">
      <Search
        placeholder="输入搜索词,多个词之间逗号分隔"
        readonly
        left-icon=""
        right-icon="search"
        background="transparent"
      />
    </div>
  </div>
  <div class="home-card-wrap">
    <div class="home-card">
      <div class="card-header">
        <div class="my-noticebar">
          <NoticeBar
            left-icon="volume-o"
            background="#fff"
            color="#A9ABAD"
            :text="state.text"
          >
            <template #left-icon>
              <div class="my-icon">
                <Icon name="volume" size="12" color="#0080FF" />
                <span>通知</span>
              </div>
            </template>
          </NoticeBar>
        </div>
      </div>
      <div class="card-body">
        <VanImage
          width="100%"
          height="100%"
          fit="contain"
          :src="state.cardBg"
        />
      </div>
      <div class="card-footer">
        <div class="footer-item" @click="subscribeHandle">
          <Icon class-prefix="iconfont" name="dingyue" size="16" color="#007FFF" />
          <span>订阅的检索式</span>
        </div>
        <div class="footer-item" @click="advanceHandle">
          <Icon class-prefix="iconfont" name="gaojisousuo" size="16" color="#007FFF" />
          <span>高级检索</span>
        </div>
      </div>
    </div>
  </div>
  <div class="content-wrap">
    <div class="title">疾病领域</div>
    <div class="content">
      <div
        class="content-item"
        v-for="(item, index) in state.fileds"
        :key="index"
        @click="clickHandle(item)"
      >
        <div class="name">{{ item.name }}</div>
        <div class="img">
          <VanImage
            width="100%"
            height="100%"
            fit="contain"
            :src="imgList[index]"
          ></VanImage>
        </div>
      </div>
    </div>
  </div>
  <Popup
    v-model:show="show"
    round
    position="bottom"
    :style="{ height: '40%' }"
  >
    <div class="popup-title">{{ state.selectTitle }}</div>
    <div class="popup-content">
      <div class="popup-content-item" v-for="(item, index) in state.secondField" :key="index" @click="itemsHandle(item)">
        {{ item.name }}
      </div>
    </div>
  </Popup>
</template>

<script>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { Search, NoticeBar, Icon, Image as VanImage, Popup, Dialog } from 'vant'
import { getFields } from '@/api/'
export default {
  components: {
    Search,
    NoticeBar,
    Icon,
    VanImage,
    Popup
  },
  setup () {
    // document.title = '文献库首页'
    const state = reactive({
      text: '文献总量80000，7日更新总量10000',
      cardBg: require('../../assets/card.png'),
      fileds: [],
      selectTitle: '',
      secondField: [],
      firstFields: '',
      secondFields: ''
    })
    const show = ref(false)
    const imgList = [
      require('../../assets/tumor.png'),
      require('../../assets/ganran.png'),
      require('../../assets/xinxueguan.png'),
      require('../../assets/xueye.png'),
      require('../../assets/neifenmi.png'),
      require('../../assets/shengzhi.png'),
      require('../../assets/renshen.png'),
      require('../../assets/diabetes.png')
    ]
    const router = useRouter()
    console.log(window.localStorage.getItem('has_pwd'))

    getFields().then(res => {
      console.log(res)
      if (res.code === 200) {
        state.fileds = res.data
        if (window.localStorage.getItem('has_pwd') === 'false') {
          Dialog.confirm({
            title: '完善密码',
            message: '首次登陆需要设置您的密码',
            confirmButtonText: '设置密码',
            cancelButtonText: '退出',
            confirmButtonColor: '#0c86fe',
            closeOnPopstate: false
          })
            .then(() => {
              // on confirm
              console.log('设置密码')
              router.push('/setPassword')
            })
            .catch(() => {
              // on cancel
              // window.localStorage.removeItem('token')
              // window.localStorage.removeItem('userId')
            })
        }
      }
    })

    const clickHandle = (item) => {
      state.firstFields = item.name
      if (item.items.length) {
        show.value = true
        state.selectTitle = item.name
        state.secondField = item.items
      } else {
        router.push(`/fieldList?field_id=${item.id}&field=${state.firstFields}`)
      }
    }

    const itemsHandle = (item) => {
      state.secondFields = item.name
      router.push(`/fieldList?field_id=${item.id}&field=${state.firstFields}-${state.secondFields}`)
    }

    const advanceHandle = () => {
      router.push('/advance')
    }

    const searchHandle = () => {
      router.push('/search')
    }

    const subscribeHandle = () => {
      router.push('/subscribe')
    }

    return {
      state,
      show,
      imgList,
      clickHandle,
      itemsHandle,
      advanceHandle,
      searchHandle,
      subscribeHandle
    }
  }
}
</script>

<style lang="less" scoped>
.head-wrap {
  width: 100%;
  height: 136px;
  background: url('../../assets/bg.png') no-repeat;
  background-size: cover;
  .search-wrap {
    &:deep(.van-search__content) {
      background: rgba(4, 5, 5, 0.2);
    }
    &:deep(.van-icon-search) {
      color: #fff;
    }
    & .van-search:deep(.van-cell) {
      padding: 7px 8px 7px 0;
      background: transparent;
    }
  }
}
.home-card-wrap {
  padding: 0 15px 0 16px;
  .home-card {
    width: 100%;
    height: 220px;
    background: #FFFFFF;
    box-shadow: 3px 3px 6px 0px #D9E0EB;
    border-radius: 6px;
    margin-top: -77px;
    display: flex;
    flex-direction: column;
    .card-header {
      height: 39px;
      border-bottom: 1px solid #EAEAEA;
      .my-noticebar {
        // margin-top: 12px;
        height: 100%;
        &:deep(.van-notice-bar) {
          border-radius: 6px 6px 0 0;
          padding: 0 10px;
        }
        &:deep(.van-notice-bar) {
          height: 100%;
        }
        .my-icon {
          width: 72px;
          height: 21px;
          background: rgba(0,122,255,0.06);
          border-radius: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 12px;
          & > span {
            font-size: 12px;
            color: #0080ff;
            margin-left: 6px;
          }
        }
      }
    }
    .card-body {
      flex: 1;
    }
    .card-footer {
      display: flex;
      height: 37px;
      border-top: 1px solid #EAEAEA;
      .footer-item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        &:nth-of-type(1) {
          border-right: 1px solid #EAEAEA;
        }
        & > span {
          font-size: 14px;
          color: #007FFF;
          line-height: 14px;
          margin-left: 11px;
        }
      }
    }
  }
}
.content-wrap {
  padding: 0 15px 0 15px;
  margin-top: 15px;
  .title {
    padding-left: 4px;
    font-size: 16px;
    font-weight: 600;
    color: #4C5E5C;
    line-height: 22px;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 6px;
    .content-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 12px 0 18px;
      box-sizing: border-box;
      width: 164px;
      height: 70px;
      background: linear-gradient(180deg, #FFFFFF 0%, #E1E7ED 100%, #D9E8F6 100%, #D9E8F6 100%);
      box-shadow: 3px 3px 6px 0px #D9E0EB;
      border-radius: 6px;
      margin-bottom: 12px;
      &:nth-of-type(1) {
        margin-right: 16px;
      }
      &:nth-of-type(3) {
        margin-right: 16px;
      }
      &:nth-of-type(5) {
        margin-right: 16px;
      }
      &:nth-of-type(7) {
        margin-right: 16px;
      }
      .name {
        font-size: 14px;
        color: #64686D;
        line-height: 16px;
      }
      .img {
        width: 40px;
        height: 40px;
      }
      &:nth-of-type(4) {
        .name {
          width: 60px;
        }
      }
    }
  }
}
.popup-title {
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 25px;
  margin-top: 20px;
}
.popup-content {
  margin-top: 20px;
  padding: 0 15px 0 16px;
  display: flex;
  flex-wrap: wrap;
}
.popup-content-item {
  width: 164px;
  height: 36px;
  background: #EBF5FF;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-bottom: 16px;
  &:nth-of-type(2n-1) {
    margin-right: 16px;
  }
  &:active {
    background: #1D85FF;
    color: #fff;
  }
}
</style>
